import './FaqPart.scss'

import React, { FunctionComponent, useState } from 'react'
import { Collapse } from 'react-collapse'
import EasySoftwareEditorContainer from 'Root/Shared/components/EasySoftwareEditorContainer/EasySoftwareEditorContainer'
import { useEditorStatus } from 'Root/Shared/utils/edit/useEditorStatus'
import { getLocalizedUrl } from 'Root/Shared/utils/localeURL'
import PuxLink from 'Root/Shared/utils/PuxLink'
import PuxWysiwyg, {
  PuxWysiwygHtmlBodyType,
} from 'Shared/components/PuxWysiwyg/PuxWysiwyg'

export interface FaqPartType {
  contentType: 'EasySoftwareFaq'
  contentItemId: string
  displayText: string
  createdUtc: string
  faqAnswer: PuxWysiwygHtmlBodyType
  path: string
}

const FaqPart: FunctionComponent<FaqPartType> = (props) => {
  const [isOpened, setIsOpened] = useState(false)
  const isEditor = useEditorStatus()

  return (
    <div className='pux-repeater-item FaqPart-wrapper'>
      <div className='FaqPart-item'>
        {isEditor ? (
          <div className="FaqPart-question">
            <PuxLink to={`${getLocalizedUrl(props.path)}`}>
              {props.displayText}
            </PuxLink>
          </div>
        ) : (
          <>
            <div
              className={`FaqPart-question${isOpened ? ` is-opened` : ``}`}
              onClick={() => setIsOpened(!isOpened)}
            >
              <div className='FaqPart-dropdown'>{props.displayText}</div>
            </div>
            <Collapse isOpened={isOpened}>
              <div className='FaqPart-answer'>
                <PuxWysiwyg content={props.faqAnswer} wysiwygId={props.contentItemId} inline />
              </div>
            </Collapse>
          </>
        )}
      </div>
    </div>
  )
}

export default FaqPart
