import './FaqDetail.scss'

//@ts-ignore
import Layout from '@componentsfld/core/Layout'
import { HeadProps } from 'gatsby'
import React, { FunctionComponent } from 'react'
import PuxContent from 'Root/Shared/components/PuxContent/PuxContent'
import { IPageContext } from 'Root/Shared/utils/data/buildTypedPage'
import EasySoftwareBreadcrumbs from 'Shared/components/EasySoftwareBreadcrumbs/EasySoftwareBreadcrumbs'
import EasySoftwareEditorContainer from 'Shared/components/EasySoftwareEditorContainer/EasySoftwareEditorContainer'
import PuxWysiwyg from 'Shared/components/PuxWysiwyg/PuxWysiwyg'
import { GenericPageProps, IEasySoftwareFaq } from 'Shared/queries/page-queries'
import PuxMetaTagsGatsby from 'Shared/utils/PuxMetaTagsGatsby'

const FaqDetail: FunctionComponent<GenericPageProps<IEasySoftwareFaq>> = (props) => {
  const pageData = props.pageContext.pageData
  const breadcrumbsData = props.pageContext.breadcrumbs
  const headerData = props.pageContext.headerData
  const footerData = props.pageContext.footerData
  const editorPath = `faqAnswer,html`

  return (
    <Layout
      localizedPath={pageData.localization?.localizations}
      footerData={footerData}
      headerData={headerData}
    >
      <div className='Container'>
        <div className={`FaqDetail`}>
          <EasySoftwareBreadcrumbs items={breadcrumbsData} />
          <PuxContent
            contentModifier='faqDetail'
            sideCards={props.pageContext.sideCardWidgets}
          >
            <h1 className={`FaqDetail-title`}>
              <EasySoftwareEditorContainer
                type="input"
                pageContentItemId={pageData.contentItemId}
                content={pageData.displayText}
                editorPath={"displayText"}
                wysiwygId={pageData.contentItemId + `-title`}
              />
            </h1>
            <EasySoftwareEditorContainer
              pageContentItemId={pageData.contentItemId}
              content={pageData.faqAnswer}
              editorPath={editorPath}
              wysiwygId={pageData.contentItemId + `-answer`}
            />
          </PuxContent>
        </div>
      </div>
    </Layout>
  )
}

export default FaqDetail

export function Head(props: HeadProps<never, IPageContext<IEasySoftwareFaq>>) {
  const pageData = props.pageContext.pageData
  const metaTags = props.pageContext.metaTags

  return <PuxMetaTagsGatsby {...metaTags} localizedPaths={pageData.localization?.localizations} />
}
