import './EasySoftwareEditorBar.scss'

import React from 'react'
import { toast } from 'react-toastify';

const EasySoftwareLightEditorBar = ({ isAuthenticated = true, wysiwygOptions = undefined, isPreview = false }) => {

  if (wysiwygOptions) {
    return (
      // <div className='EditorBar EditorBar--wysiwyg' style={{ transform: `translate(0, ${wysiwygOptions.active ? '0' : '-100%'})` }}>
      <div className='EditorBar EditorBar--wysiwyg' style={{ transform: `translate(0, 0)` }}>
        <div className='EditorBar-container'>
          <WysiwygEditorBar wysiwygOptions={wysiwygOptions} />
        </div>
      </div >
    )
  }

  return (
    <div className='EditorBar'>
      <div className='EditorBar-container'>
        <EditorBar isAuthenticated={isAuthenticated} isPreview={isPreview} />
      </div>
    </div >
  )
}

const EditorBar = ({ isAuthenticated = true, isPreview }) => {
  function savePage() {
    const saveButtons: NodeListOf<HTMLButtonElement> = document.querySelectorAll(`.WysiwygEditor-button.WysiwygEditor-button--changed`)

    if (saveButtons.length === 0) {
      toast.info(`Nothing to save.`)
      return
    }

    saveButtons.forEach((saveButton: HTMLButtonElement) => {
      saveButton.click()
    })
  }

  return (
    <>
      <div>{isPreview ? "Preview" : "Editor"} mode</div>
      {isAuthenticated ? (
        null
      ) : (
        <a
          className='Button Button--primary Button--green Button--medium'
          href={`${process.env.GATSBY_ORCHARD_API_URL}/Login`}
          target='_blank'>
          Login to {isPreview ? "view" : "edit"} 🚀
        </a>
      )}
    </>
  )
}

const WysiwygEditorBar = ({ wysiwygOptions }) => {
  return (
    <>
      <div>Input mode</div>
      <button
        className='Button Button--primary Button--orange Button--medium ml-auto'
        onClick={() => wysiwygOptions.closeHandler()}
      >
        Close editor
      </button>
      <button
        className='Button Button--primary Button--green Button--medium'
        onClick={() => wysiwygOptions.saveHandler()}
        ref={wysiwygOptions?.saveButtonRef}
      >
        Save editor 💾
      </button>
    </>
  )
}

export default EasySoftwareLightEditorBar
